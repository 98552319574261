<template>
    <div class="counterbg py-6 bg-green-l bg-cover txt-white">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12">
                    <p class="font-weight-bold fs-2 fl-x-cc">CORE VALUES</p>
                    <p class="fs-1 fl-x-cc pb-5 pl-4 pr-4 pl-lg-0 pr-lg-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Amet delectus exercita saepe sed.</p>
                </div>
                <div class="col-12 col-lg-3 border-r ">
                    <base-counter to="352" heading="Core Value 1" count="352" class="line-right mt-lg-0 mt-4"
                                  icon="">
                    </base-counter>
                    <p class="fs--2 fl-j-c j-center p-lg-2 pl-4 pr-4 pl-lg-0 pr-lg-0 mb-5 mb-lg-1">Lorem ipsum saepe jkjnm jmnn gthb kjnj ipsum isatt dolor sitm ipsum
                        dolor sit sit m ipsum dolm ipsum dolor dolor </p>
                </div>
                <div class="col-12 col-lg-3 border-r ">
                    <base-counter to="352" heading="Core Value 2" count="3" class="line-right mt-5 mt-lg-0"
                                  icon="">
                    </base-counter>
                    <p class="fs--2 fl-j-c j-center p-2 p-lg-2 mb-5 mb-lg-1 pl-4 pr-4 pl-lg-0 pr-lg-0">Lorem ipsum saepe jkjnm jmnn gthb kjnj ipsum isatt dolor sitm ipsum
                        dolor sit sit m ipsum dolm ipsum dolor dolor  </p>

                </div>
                <div class="col-12 col-lg-3 border-r ">
                    <base-counter to="352" heading="Core Value 3" count="7" class="line-right mt-5 mt-lg-0"
                                  icon="">
                    </base-counter>
                  <p class="fs--2 fl-j-c j-center p-2 mb-5 mb-lg-1 pl-4 pr-4 p-lg-2  pl-lg-0 pr-lg-0">Lorem ipsum saepe jkjnm jmnn gthb kjnj ipsum isatt dolor sitm ipsum
                        dolor sit sit m ipsum dolm ipsum dolor dolor  </p>

                </div>
                <div class="col-12 col-lg-3 ">
                    <base-counter to="352" heading="Core Value 4" count="3000" class="mt-5 mt-lg-0"
                                  icon="">
                    </base-counter>
                    <p class="fs--2 fl-j-c j-center p-2 mb-5 mb-lg-1 pl-4 pr-4 p-lg-2  pl-lg-0 pr-lg-0">Lorem ipsum saepe jkjnm jmnn gthb kjnj ipsum isatt dolor sitm ipsum
                        dolor sit sit m ipsum dolm ipsum dolor dolor </p>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import BaseCounter from '@components/Career/BaseCounter';

export default {
    name: 'CoreValue',
    components: { BaseCounter }
};
</script>

<style scoped lang="scss">
.counterbg {
    background-image: url("../../assets/web/Images/Gradient/blue-gradient.png");
    background-size: cover;
}

.txt-white {
    color: white;
}
.line-right{
    position: relative;
    &::after{
        content: '';
    position: absolute;
    bottom: -20px;
    left: 94%;
    transform: rotate(90deg);
    width: 3.2rem;
    height: 2px;
    background-color: var(--color-white);
    }
}
@media(max-width:992px){

.line-right{
    position: relative;
    &::after{
        content: '';
    position: absolute;
    bottom: -124px;
    left: 44%;
    transform: rotate(0deg);
    width: 3.2rem;
    height: 2px;
    background-color: var(--color-white);
    }
}
}
</style>
