<template>
  <div class="career-banner">
  </div>
</template>
<script>
export default {
    name: 'CareerBanner',
    components: {}
};
</script>
<style>
.career-banner {
  background-image: url("../../assets/web/Images/Gradient/Career-image.png");
  min-height: 45vh;
  background-size: cover;
  background-position: 0% 50%;
}
</style>
