<template>
    <div class="py-5 pt-52">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <base-heading-with-info class="mb-5" title="Solution Mildstones"
                                            info="There are many variations of passages of Lorem Ipsum available alteration in some form, by injected"
                    >
                    </base-heading-with-info>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="px-3 text-left fs-lg-1 font-weight-500">
                        <span class="font-poppins-normal font-weight-600">Lorem ipsum dolor sit amet,</span>consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore
                        et dolore
                        magna aliqua.
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="px-3 text-left fs-lg-1 mt-4 mt-lg-0">
                        <span class="font-poppins-semibold">Lorem ipsum dolor sit amet,</span>consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore
                        et dolore
                        magna aliqua.
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="px-3 text-left fs-lg-1 mt-4 mt-lg-5">
                        <span class="font-poppins-semibold">Lorem ipsum dolor sit amet,</span>consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore
                        et dolore
                        magna aliqua.
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="px-3 text-left fs-lg-1 mt-4 mt-lg-5">
                        <span class="font-poppins-semibold">Lorem ipsum dolor sit amet,</span>consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore
                        et dolore
                        magna aliqua.
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="px-3 text-left fs-lg-1 mt-4 mt-lg-5">
                        <span class="font-poppins-semibold">Lorem ipsum dolor sit amet,</span>consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore
                        et dolore
                        magna aliqua.
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="px-3 text-left fs-lg-1 mt-4 mt-lg-5">
                        <span class="font-poppins-semibold">Lorem ipsum dolor sit amet,</span>consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore
                        et dolore
                        magna aliqua.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseHeadingWithInfo from '@components/ui/BaseHeadingWithInfo';

export default {
    name: 'SolutionMildstones',
    components: { BaseHeadingWithInfo }
};
</script>

<style scoped>

</style>
