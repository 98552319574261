<template>
    <div class="py-4 pb-7 pb-18">
        <div class="container">
            <!--        <section-card></section-card>-->
            <team-b></team-b>
        </div>
    </div>
</template>

<script>
// import SectionCard from '../SectionCard';
import TeamB from '@components/Our-Team/Team-b';

export default {
    name: 'OurMissionVision',
    components: { TeamB }
};
</script>

<style scoped>
@media (min-width: 768px) and (max-width: 1024px) {
    .pb-18{
        padding-bottom: 21rem !important;
    }
}
</style>
