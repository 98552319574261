<template>
    <div class="pb-4 c-bs bg-2">
        <div class="card-min-h"
             :style="{'background-image':'url('+ imgUrl +')', 'background-size':'cover', 'background-repeat':'no-repeat', 'background-position':'0% 12%'}">
        </div>
        <short-section :link="route" :heading="heading" :source="source" :role="role" :author="author" :size="size"
                       class="mt-42">
            <template #content>
                <!--                <p class="fs-lg-1 text-left pl-lg-4 mb-lg-3"> {{ content.substring(0, 110) }}</p>-->
                <p class="fs-lg-1 mt-4 text-center px-3 mb-52 text-6">
                    {{content}}
                </p>
            </template>

            <template #buttons>
                <router-link :to="'our-team#' + item_id" class="fs--1 text-primary text-decoration-none">
                        <div class="fl-x-cc">
                            <span class="mr-1 color-primary font-poppins-medium">View More</span>
                            <!--                            <i class="fa fa-chevron-right"></i>-->
                            <img src="../../assets/web/icons/icons8-chevron-right-8.png" class="" alt="">
                        </div>
                </router-link>
            </template>
        </short-section>
    </div>
</template>
<script>
import ShortSection from './ShortSection';

export default {
    name: 'MemberCard',
    components: { ShortSection },
    props: {
        heading: {
            type: String,
            default: ''
        },
        route: {
            type: String,
            default: ''
        },
        role: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        author: {
            type: String,
            default: ''
        },
        img: {
            type: Image
        },
        imgUrl: {
            type: String
        },
        content: {
            type: String,
            default: ''
        },
        item_id: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }
    }
};
</script>
<style lang="scss" scoped>
.card-min-h {
    position: relative;
    height: 14rem;

    img {
        width: 100% !important;
        height: 100% !important;
    }
}

.c-bs {
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
}
.icon-right-abs {
    position: relative;

    &::after {
        content: '';
        right: 50%;
        width: 8px;
        height: 8px;
        background: url("../../assets/web/icons/icons8-chevron-right-8.png");
        background-size: contain;
    }

}
</style>
