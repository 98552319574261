<template>
<div class="pt-8 pt-lg-7 pb-lg-4 pb-5">
    <div class="container">
        <div class="row">
           <div class="column-lg-12 col-12">
               <div>
                   <div title="Faq" class="">
                            <div class="col-12 col-lg-11">
                                <!--                                <img src="../../assets/web/course-offered/bgi.jpg" class="img-fluid" alt="">-->
                                <p class="font-poppins-bold fs-4 fl-x-cc  text-primary ">
                                    Meet Our Teams</p>
                                <p class="font-poppins-regular fs--1 text-primary fl-x-cc">Lorem ipsum dolor sit amet,
                                    consectetur adipisicing elit.</p>
                            </div>
                        </div>
               </div>
           </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'MeetOur-Team'
};
</script>

<style scoped>

</style>
