<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <base-heading-with-info :text-uppercase="false" class="mb-4" size="lg" title="Latest News"
                                            info="There are many variations of passages of Lorem Ipsum available alteration in some form, by injected"
                    >
                    </base-heading-with-info>
                </div>
                <div class="col-12">
                    <div class="c-tabs">
                        <tabs align="center" class="c-tabs">
                            <tab title="View All">
                                <div class="row mt-5">
                                    <div class="col-12 col-lg-4 col-md-6">
                                        <article-card
                                            id="124"
                                            hour="03"
                                            heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                            content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                            :img="newsImg1"
                                            categ="category"
                                        ></article-card>
                                    </div>
                                    <div class="col-12 col-lg-4 col-md-6">
                                        <article-card
                                            id="124"
                                            hour="03"
                                            heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                            content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                            :img="newsImg2"
                                            categ="category"
                                        ></article-card>
                                    </div>
                                    <div class="col-12 col-lg-4 col-md-6">
                                        <article-card
                                            id="124"
                                            hour="03"
                                            heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                            content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                            :img="newsImg3"
                                            categ="category"
                                        ></article-card>
                                    </div>
                                    <div class="col-12 col-lg-4 col-md-6">
                                        <article-card
                                            id="124"
                                            hour="03"
                                            heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                            content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                            :img="newsImg1"
                                            categ="category"
                                        ></article-card>
                                    </div>
                                    <div class="col-12 col-lg-4 col-md-6">
                                        <article-card
                                            id="124"
                                            hour="03"
                                            heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                            content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                            :img="newsImg2"
                                            categ="category"
                                        ></article-card>
                                    </div>
                                    <div class="col-12 col-lg-4 col-md-6">
                                        <article-card
                                            id="124"
                                            hour="03"
                                            heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                            content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                            :img="newsImg3"
                                            categ="category"
                                        ></article-card>
                                    </div>
                                    <div class="col-12">
                                    </div>
                                </div>
                            </tab>
                            <tab title="Finance">
                            </tab>
                            <tab title="Business">
                            </tab>
                            <tab title="Collection">
                            </tab>
                        </tabs>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ArticleCard from '@components/ui/ArticleCard';
import newsImg1 from '../../../assets/web/home/news/news-img-1.png';
import newsImg2 from '../../../assets/web/home/news/news-img-2.png';
import newsImg3 from '../../../assets/web/home/news/news-img-3.png';
export default {
    name: 'NewsListTabs',
    components: { ArticleCard },
    data () {
        return {
            newsImg1: newsImg1,
            newsImg2: newsImg2,
            newsImg3: newsImg3
        };
    }
};
</script>

<style scoped>

</style>
