<template>
    <div class="container">
        <div class="row mb-6">
            <div class="col-12 col-lg-12 col-md-12 mt-lg-4 mb-3 mt-7">
                <p class="font-weight-bold fs-2 fl-x-cc blue">WHY WORK AT ADVENT?</p>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <div class="card bs-2 mt-lg-4 mt-3 bradius c-bs">
                    <p class="pt-6 font-weight-bold fs-lg-1">CORE VALUES</p>
                    <p class="fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Alias facere harum possimus!</p>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <div class="card bs-4 mt-lg-4 mt-3 bradius c-bs">
                    <p class="pt-6 font-weight-bold fs-lg-1">Core Value</p>
                    <p class="fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Alias facere harum possimus!</p>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <div class="card bs-4 mt-lg-4 mt-3 bradius c-bs">
                    <p class="pt-6 font-weight-bold fs-lg-1">Core Value</p>
                    <p class="fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Alias facere harum possimus!</p>
                </div>
            </div>

            <div class="col-12 col-lg-4 col-md-6">
                <div class="card bs-4 mt-lg-6 mt-3 bradius c-bs ">
                    <p class="pt-6 font-weight-bold fs-lg-1">Core Value</p>
                    <p class="fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Alias facere harum possimus!</p>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <div class="card bs-4 mt-lg-6 mt-3 bradius c-bs">
                    <p class="pt-6 font-weight-bold fs-lg-1">Core Value</p>
                    <p class="fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Alias facere harum possimus!</p>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6">
                <div class="card bs-4 mt-lg-6 mt-3 bradius c-bs">
                    <p class="pt-6 font-weight-bold fs-lg-1">Core Value</p>
                    <p class="fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Alias facere harum possimus!</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'CoreValue-Card.vue'
};
</script>

<style scoped>
.c-bs {
    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.bradius{
    border-radius:0px!important;
}
.blue{
    color:darkblue;
}
</style>
