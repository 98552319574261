<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-12">
                    <p class="font-poppins-bold fs-2 text-primary fl-x-cc mb-2  mt-5">CONTACT US</p>
                    <p class=" fs-1 fl-x-cc mb-4 ">our friendly team would love to hear from us!</p>
                </div>
                <div class="col-lg-5 col-12 pl-lg-5">
                    <p class="text-primary fs-1 font-poppins-bold ">Get in Touch!</p>
                    <p class="fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing?</p>
                    <p class="font-poppins-regular mb-1">Adress:</p>
                    <p class="font-poppins fs--1">Advent Credit Solutions Pvt Ltd,<br>
                        Address demo text editor<br>
                        Lorem ipsum dolor sit amet.<br>
                        Ernakulam-680508, Kerala</p>
                    <p class="font-poppins-regular mb-1">Phone:</p>
                    <p class="font-poppins fs--1">A563-3u4-374
                    </p>
                    <p class="font-poppins-regular mb-1">Fax:</p>
                    <p class="font-poppins fs--1">A563-3u4-374
                    </p>
                    <p class="font-poppins-regular mb-1">Email:</p>
                    <p class="font-poppins fs--1">A563-m3u4@gmail.com
                    </p>
                    <p class="font-poppins-regular mb-1">CIN:</p>
                    <p class="font-poppins fs--1">A566y8778u888
                    </p>
                    <p class="font-poppins-regular mb-1">GSTIN:</p>
                    <p class="font-poppins fs--1">4567876787878798
                    </p>
                </div>
                <div class="col-lg-7 col-12">
                    <div class="pl-lg-5">

                        <div class="card bs-6 bradius mt-3">
                            <s-form>
                                <div class="row">
                                    <div class="col-lg-6 ">
                                        <validated-input size="m" placeholder="Your Name" border-radius="0"
                                                         class="py-1 c-input mt-3"></validated-input>
                                    </div>
                                    <div class="col-lg-6 ">
                                        <validated-input size="m" placeholder="Phone Number" border-radius="0"
                                                         class="py-1 c-input mt-3"></validated-input>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <validated-input size="m" placeholder="Email ID" border-radius="0"
                                                         class="py-1 c-input"></validated-input>
                                    </div>

                                    <div class="col-lg-6">
                                        <validated-select size="m" border-radius="0" class="mb-3 c-input c-select-field"
                                                          aria-placeholder="District" :options="options"
                                                          placeholder="District"></validated-select>
                                    </div>

                                </div>
                                <div>
                                    <validated-input size="m" placeholder="Enter Subject" border-radius="0"
                                                     class="py-1 c-input"></validated-input>
                                </div>

                                <div class="">
                                    <validated-text-area size="m" placeholder="Phone Number" class=" pb-2 c-input"
                                                         border-radius="0"
                                                         name="message"></validated-text-area>
                                </div>
                                <div class="row">

                                    <div class="col-lg-6 col-7">
                                        <validated-check-box label="Show password"></validated-check-box>

                                    </div>
                                    <div class="col-lg-6 col-5 fl-x-br">
                                        <btn size="sm" text="Submit" icon="fa fa-arrow-right"
                                             border-radius="5" icon-alignment="right"
                                             class="px-3 plain-button mb-5"></btn>

                                    </div>
                                </div>
                            </s-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card-Contact'
};
</script>

<style scoped>
.bradius {
    border-radius: 0px !important;
}

</style>
