<template>
    <div class="about bg-2">
        <div id="top-banner">
            <base-banner :img="aboutBannerImg" class="" height="full" heading="" heading-align="center"
                         :overlay="true">
            </base-banner>
        </div>
        <div id="about-our-company">
            <about-our-company></about-our-company>
        </div>
        <div id="our-mission-vision">
            <our-mission-vision></our-mission-vision>
        </div>
        <div id="core-value-counter">
            <core-value></core-value>
        </div>
        <div id="whyChooseUs">
            <why-choose-us></why-choose-us>
        </div>
        <div id="our-features">
            <our-features></our-features>
        </div>
        <div id="premium-membership">
            <premium-membership></premium-membership>
        </div>
        <div id="top-foote">
            <top-footer-image></top-footer-image>
        </div>
    </div>
</template>
<script>
import aboutBannerImg from '../assets/web/about/About-Us-Banner.png';
import AboutOurCompany from '../components/about/sections/AboutOurCompany';
import OurMissionVision from '../components/about/sections/OurMissionVision';
import CoreValue from '../components/Career/CoreValue';
import WhyChooseUs from '../components/about/sections/WhyChooseUs';
import OurFeatures from '@components/about/sections/OurFeatures';
import PremiumMembership from '@components/home/sections/PremiumMembership';
import TopFooterImage from '@components/home/sections/TopFooterImage';

export default {
    name: 'About',
    components: {
        TopFooterImage,
        PremiumMembership,
        OurFeatures,
        WhyChooseUs,
        CoreValue,
        OurMissionVision,
        AboutOurCompany
    },
    data () {
        return {
            aboutBannerImg: aboutBannerImg
        };
    }
};
</script>

<style scoped>

</style>
