import { render, staticRenderFns } from "./TestimonialsSwiper.vue?vue&type=template&id=a2b3959e&scoped=true&"
import script from "./TestimonialsSwiper.vue?vue&type=script&lang=js&"
export * from "./TestimonialsSwiper.vue?vue&type=script&lang=js&"
import style0 from "./TestimonialsSwiper.vue?vue&type=style&index=0&id=a2b3959e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2b3959e",
  null
  
)

export default component.exports