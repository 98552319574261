<template>
    <div class="py-4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="fs-2 text-primary font-poppins-bold mb-4">Related News</h2>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-lg-4 col-md-6">
                            <article-card
                                id="124"
                                hour="03"
                                heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                :img="newsImg1"
                                categ="category"
                            ></article-card>
                        </div>
                        <div class="col-12 col-lg-4 col-md-6">
                            <article-card
                                id="124"
                                hour="03"
                                heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                :img="newsImg2"
                                categ="category"
                            ></article-card>
                        </div>
                        <div class="col-12 col-lg-4 col-md-6">
                            <article-card
                                id="124"
                                hour="03"
                                heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                :img="newsImg3"
                                categ="category"
                            ></article-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArticleCard from '@components/ui/ArticleCard';
import newsImg1 from '../../assets/web/home/news/news-img-1.png';
import newsImg2 from '../../assets/web/home/news/news-img-2.png';
import newsImg3 from '../../assets/web/home/news/news-img-3.png';

export default {
    name: 'RelatedNews',
    components: { ArticleCard },
    data () {
        return {
            newsImg1: newsImg1,
            newsImg2: newsImg2,
            newsImg3: newsImg3
        };
    }
};
</script>

<style scoped>

</style>
