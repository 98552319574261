<template>
    <div class="pt-lg-5 pt-9 pb-5">
        <div class="container">
            <!--            <section-card img-align="left">-->
            <!--                <template #content>-->
            <!--                    <h2 class="mb-5 fs-lg-4 text-primary font-poppins-semibold text-uppercase">WHY CHOOSE US ?</h2>-->
            <!--                    <p class="mb-4">-->
            <!--                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut-->
            <!--                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation-->
            <!--                        <br><br>-->
            <!--                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla-->
            <!--                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt-->
            <!--                        mollit anim id est laborum-->
            <!--                    </p>-->
<!--                                <btn design="basic-b" text="Contact Us" size="lg" class="pr-4 ml-lg-9 lego-btn-arrow-right"-->
<!--                                     border-radius="4"></btn>-->
            <!--                </template>-->
            <!--            </section-card>-->
            <team-f></team-f>
        </div>
    </div>
</template>

<script>
// import SectionCard from '@components/about/SectionCard';
import TeamF from '@components/Our-Team/Team-f';

export default {
    name: 'WhyChooseUs',
    components: { TeamF }
};
</script>

<style scoped>

</style>
