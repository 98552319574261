<template>
    <div class="bg-gradient-5 of-h mb--1re">
        <div class="container pt-5 pb-5">
            <div class="row">
                <div class="col-12 text-center">
                    <p class="mb-3 text-primary fs-lg-1">Advent Credit Solutions</p>
                    <h4 class="text-primary font-poppins-medium mb-4">Get More Features With Premium Membership </h4>
                    <p class="mb-41 text-3">Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do
                        eiusmod tempor mgnhe
                        incididunt </p>
                    <btn design="basic-b" text="Register Now" size="lg" class="pr-4 pl-3 lego-btn-arrow-right"
                         border-radius="4"></btn>
                </div>
            </div>
        </div>

<!--        <div class="fl-x fl-j-c c-res-mb&#45;&#45;15re">-->
<!--            <img src="../../../assets/web/Images/Dots.png" class="img-fluid" alt="">-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: 'PremiumMembership'
};
</script>

<style scoped lang="scss">
.bottom-img {
    min-height: 85vh;
    padding-bottom: var(--spacer-7) !important;
    @media (min-width: 992px) {
        min-height: 120vh;
    }
    position: relative;
    //background: url("../../../assets/web/Images/Dots.png");
    //background-position: bottom;
    &::after {
        content: '';
        position: absolute;
        bottom: -234px;
        @media (min-width: 992px) {
            bottom: 0;
        }
        background: url("../../../assets/web/Images/Dots.png");
        //background-position: top;
        background-repeat: no-repeat;
        background-position: 50% 5%;
        margin-bottom: -10px;
        //height: 80vh;
        height: 90%;
        width: 100%;
    }
}

.c-res-mb--15re {
    margin-bottom: -4rem;
    @media (min-width: 992px) {
        margin-bottom: -15rem;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: -8rem;
    }
}

.pos-a-bt {
    position: absolute;
    bottom: -2rem;
    z-index: 999 !important;
}
</style>
