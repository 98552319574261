<template>
    <div class="py-5 bg-gradient-2">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <base-heading-with-info class="mb-5" title="Testimonials"
                                            info="There are many variations of passages of Lorem Ipsum available alteration in some form, by injected"
                    >
                    </base-heading-with-info>
                </div>
                <div class="col-12">
                    <testimonials-swiper></testimonials-swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TestimonialsSwiper from '@components/home/sections/testimonials/TestimonialsSwiper';
export default {
    name: 'TestimonialsSection',
    components: { TestimonialsSwiper }
};
</script>

<style scoped>

</style>
