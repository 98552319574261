<template>
    <div>
        <div class=" background-grey">
            <div class="container">
                <p class="font-poppins-semibold fs-4 text-primary pt-5 fl-x-cc">Submit Your Applictaions</p>
                <div class="fl-x-cc">
                    <div class="col-12 col-lg-7 ">

                        <s-form>

                            <div class="row">
                                <div class="col-4 col-lg-5 mt-4 ">
                                    <label class=" text-primary fs-1 font-poppins-medium">Full Name</label>
                                </div>
                                <div class="col-lg-7 col-8">
                                    <validated-input size="m" border-radius="0"
                                                     class="py-1  c-input-career mt-3"></validated-input>
                                </div>

                                <div class="col-4 col-lg-5 mt-4 ">
                                    <label class=" text-primary fs-1 font-poppins-medium">Email Id</label>
                                </div>
                                <div class="col-lg-7 col-8">
                                    <validated-input size="m" border-radius="0"
                                                     class="py-1  c-input-career mt-3"></validated-input>
                                </div>
                                <div class="col-4 col-lg-5 mt-4">
                                    <label class=" text-primary fs-1 font-poppins-medium">Phone number</label>
                                </div>
                                <div class="col-lg-7 col-8">
                                    <validated-input size="m" border-radius="0"
                                                     class="py-1  c-input-career mt-3"></validated-input>
                                </div>
                                <div class="col-4 col-lg-5 mt-4">
                                    <label class=" text-primary fs-1 font-poppins-medium">Current Company</label>
                                </div>

                                <div class="col-lg-7 col-8">
                                    <validated-input size="m" border-radius="0"
                                                     class="py-1  c-input-career mt-3"></validated-input>

                                </div>
                                <div class="col-4 col-lg-5 mt-3">
                                    <label class=" text-primary fs-1 font-poppins-medium">CV/Resume</label>
                                </div>

                                <div class="col-8 col-lg-7 mt-0 ">
<!--                                    <validated-file-input placeholder="abcd" size="m" border-radius="0"-->
<!--                                                          class=" text-primary c-file-input c-resume-attach fs-1  mt-3">-->
<!--                                        <template #prepend>-->
<!--                                            <i class="fa fa-paperclip"></i>-->
<!--                                        </template>-->
<!--                                    </validated-file-input>-->
                                    <custom-file-input placeholder="abcd" size="m" border-radius="0"
                                                          class="c-resume-attach c-file-input text-primary pr-lg-8   fs--1  mt-3">
                                        <template #prepend>
                                            <i class="fa fa-paperclip"></i>
                                        </template>
                                    </custom-file-input>
<!--                                    <custom-file-input></custom-file-input>-->

                                </div>

                                <div class="col-5 col-lg-4 mt-4 ">
                                    <validated-input size="m" border-radius="0" label="#years of experience"
                                                     class="py-1 text-primary fs-1 c-input-career mt-3"></validated-input>

                                </div>
                                <div class="col-12 col-lg-12 mt-3 ">
                                    <validated-input size="m" border-radius="0"
                                                     label="if offered how soon can you join us"
                                                     placeholder="Type your response"
                                                     class="py-1 fs-1 c-input-career  text-primary"></validated-input>
                                </div>
                                <div class="col-12 col-lg-12 mt-3 ">
                                    <validated-text-area size="m" placeholder="Addition Information"
                                                         label="Additional information"
                                                         class="fs--1 pb-2 c-input text-primary c-input-career"
                                                         name="message"></validated-text-area>
                                </div>
                            </div>
                            <div class="fl-x-cc mt-4 mb-5 mb-lg-7 ">
                                <btn @click="$refs.popUpModal.show()" class="py-2 px-5">Submit</btn>

                                <modal class="c-modal modal-body no-close header-primary" :no-close-on-backdrop="false"
                                       title=""
                                       ref="popUpModal" width="25r" header-color="">
                                    <form-success-modal></form-success-modal>

                                </modal>
                            </div>

<!--                            <div>-->
<!--                                <btn text="dummy" @click="$refs.testModal.show()"></btn>-->
<!--                                <modal title="Delete" ref="testModal" dialog width="25r"-->
<!--                                       header-color="danger" ok-button-color="success" :cancel-button="false">-->
<!--                                    <h1>Are you sure.?</h1>-->
<!--                                </modal>-->

<!--                            </div>-->
                        </s-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FormSuccessModal from '@components/Career-Form/Form-Success-Modal';
import CustomFileInput from '@components/lego-custom/CustomFileInput';

export default {
    name: 'Career-Input-Form',
    components: { CustomFileInput, FormSuccessModal }
};
</script>

<style scoped lang="scss">
i:first-child, i.i-inline:first-child {
    margin-left: 1px;
    transform: rotate(225deg);
}
</style>
