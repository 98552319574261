<template>
<div>
    <div>
        <contact-banner></contact-banner>
    </div>
    <div>
        <card-contact></card-contact>
    </div>
    <div>
       <contact-map></contact-map>
    </div>
    <div>

        <premium-membership></premium-membership>
        <top-footer-image></top-footer-image>
    </div>
</div>
</template>

<script>
import ContactBanner from '@components/Contact-Us/Contact-Banner';
import ContactMap from '@components/Contact-Us/map';
import CardContact from '@components/Contact-Us/Card-Contact';
// import AdventCreditSolution from '@components/Contact-Us/Advent-Credit-solution';
import PremiumMembership from '@components/home/sections/PremiumMembership';
import TopFooterImage from '@components/home/sections/TopFooterImage';
export default {
    name: 'Contact-Us',
    components: { TopFooterImage, PremiumMembership, CardContact, ContactMap, ContactBanner }
};
</script>

<style scoped>

</style>
