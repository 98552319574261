<template>
    <div>
        <div class="container">
            <div class="row ">
                <div class="col-9 col-lg-9">
                    <p class="font-poppins-bold fs-2 text-primary mt-8">COLLECTION EXECUTIVE</p>
                      </div>
                    <div class="col-3 col-lg-3">
                    <div class="fl-x-br">
                        <btn size="sm" text="Apply for this job" border-radius="5"
                             class="px-3 plain-button mt-8 d-sm-none"></btn>
                    </div>
                </div>

            </div>
            <div class="row pl-3 pl-lg-0">
                <p class="p-btn mr-3 ml-lg-3 mb-lg-5 mb-3"><i class="fa fa-map-marker ">&nbsp;&nbsp;Remote</i></p>
                <p class="p-btn mb-lg-5 mb-3"><i class="fa fa-clock-o">&nbsp;&nbsp;Full time</i></p>
            </div>
<!--            duplicate for small screen-->
            <div class="fl-x-cc">
                        <btn size="sm" text="Apply for this job" border-radius="5"
                             class="px-4 plain-button mb-5 mt-4 d-lg-none"></btn>
                    </div>
<!--            duplicate ends-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Collection-Executive'
};
</script>

<style scoped>
.p-btn {
    border: 1px solid #222D65;
    border-radius: 16px;
    font-size:14px;
    color: #222D65;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 1px;
    padding-bottom: 1px;
}
i{
    color:#222D65;
}
@media(max-width:992px){
    .p-btn {
    border: 1px solid #222D65;
    border-radius: 16px;
    font-size: 12px;
    color: #222D65;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
}
}
</style>
