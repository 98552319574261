<template>
    <div class="col-12 col-lg-12">
        <collection-executive></collection-executive>
    </div>
</template>

<script>
import CollectionExecutive from '@components/Career-inner-page/Collection-Executive';
export default {
    name: 'Career-header',
    components: { CollectionExecutive }
};
</script>

<style scoped>

</style>
