<template>
    <div class="py-5 bg-gradient-2">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <base-heading-with-info class="mb-5" title="Latest News"
                                            info="There are many variations of passages of Lorem Ipsum available alteration in some form, by injected"
                    >
                    </base-heading-with-info>
                </div>
                <div class="col-12">
                    <latest-news-swiper></latest-news-swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LatestNewsSwiper from '@components/home/sections/latest-news/LatestNewsSwiper';
export default {
    name: 'LatestNewsSection',
    components: { LatestNewsSwiper }
};
</script>

<style scoped>

</style>
