<template>
<div>
    <div>
       <faqq></faqq>
    </div>
</div>
</template>

<script>
import Faqq from '@components/Faq/Faqq';
export default {
    name: 'Faq',
    components: { Faqq }
};
</script>

<style scoped>

</style>
