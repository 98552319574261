<template>
    <div class="fl-x">
        <div class="fl-x fs-lg-1 text-primary" v-for="(item,i) in path" :key="i">
            <router-link to="/faq/" class="mr-lg-1 text-decoration-none">{{ item }}</router-link>
            <div class="mr-lg-1">
                <img src="../../assets/web/icons/chevron-right-primary-24.png" alt="">
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'BaseBreadcrumb',
    props: {
        path: {
            type: Array,
            default: null
        }
    }
};
</script>

<style scoped>

</style>
