<template>
    <div class="background-grey py-5">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12">
                    <p class="font-weight-bold fs-1 blue fl-x-cc mt-lg-4 mt-5 mb-4">OPEN POSITIONS</p>
                </div>
                <div class="col-lg-12">
                    <div class="c-tabs">
                        <tabs align="center" class="c-tabs">
                            <tab title="View All">
                                <view-all></view-all>
                            </tab>
                            <tab title="Marketting">

                            </tab>
                            <tab title="Customer Service">

                            </tab>
                            <tab title="Collection">

                            </tab>
                            <tab title="Management">

                            </tab>
                        </tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ViewAll from '@components/Our-Position-Tabs/View-All';

export default {
    name: 'Open-Position',
    components: { ViewAll }
};
</script>

<style scoped>
.blue {
    color: darkblue;
}

.tab-bg {
    background-color: darkblue;
    border-radius: 7px;
    border: none;
}
</style>
