<template>
    <div>
        <div class="bt-1 bb-1">
            <p class="font-weight-bold fs-1 text-primary mt-3">Collection Executive</p>
            <div class="row">
                <div class="col-12 col-lg-9">
                    <p class="fs--1"> Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Itaque modi quas repellendus ut veniam. Nisi? </p>
                </div>
                <div class="col-3 col-lg-3">
                    <div class="fl-x-br">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-3 plain-button d-sm-none "></btn>
                    </div>
                </div>
            </div>

            <!--                    <btn size="sm" design="basic-b" icon="fa fa-map-marker" text="Remote" class="" border-radius="5"></btn>-->
            <!--                    <btn size="sm" design="basic-b" class="ml-3" icon="fa fa-clock-o" text="Full-time" border-radius="5"></btn>-->
            <div class="row pl-3 pl-lg-0">

                <p class="p-btn mr-3 ml-lg-3 mb-4 font-poppins-regular fs-1"><i class="fa fa-map-marker ">&nbsp;&nbsp;Remote</i>
                </p>
                <p class="p-btn mb-4 font-poppins-regular fs-1"><i class="fa fa-clock-o">&nbsp;&nbsp;Full time</i></p>

            </div>
<!--            dupilcate start for small screen-->
            <div class="fl-x-cc">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-4 plain-button mt-2 mb-4 d-lg-none"></btn>
                    </div>
<!--            duplicate end-->
        </div>
        <div class="bt-1 bb-1">
            <p class="font-weight-bold fs-1 blue mt-3">Collection Executive</p>
            <div class="row">
                <div class="col-12 col-lg-9">
                    <p class="fs--1"> Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Itaque modi quas repellendus ut veniam. Nisi? </p>
                </div>
                <div class="col-3 col-lg-3">
                    <div class="fl-x-br">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-3 plain-button d-sm-none "></btn>
                    </div>
                </div>
            </div>
            <div class="row pl-3 pl-lg-0">

                <p class="p-btn mr-3 ml-lg-3 mb-4 font-poppins-regular fs-1"><i class="fa fa-map-marker ">&nbsp;&nbsp;Remote</i>
                </p>
                <p class="p-btn mb-4 font-poppins-regular fs-1"><i class="fa fa-clock-o">&nbsp;&nbsp;Full time</i></p>
            </div>
             <!--            dupilcate start for small screen-->
            <div class="fl-x-cc">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-4 plain-button mt-2 mb-4 d-lg-none"></btn>
                    </div>
<!--            duplicate end-->
        </div>
        <div class="bt-1 bb-1">
            <p class="font-weight-bold fs-1 blue mt-3">Collection Executive</p>
            <div class="row">
                <div class="col-12 col-lg-9">
                    <p class="fs--1"> Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Itaque modi quas repellendus ut veniam. Nisi? </p>
                </div>
                <div class="col-3 col-lg-3">
                    <div class="fl-x-br">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-3 plain-button d-sm-none"></btn>
                    </div>
                </div>
            </div>
           <div class="row pl-3 pl-lg-0">

                <p class="p-btn mr-3 ml-lg-3 mb-4 font-poppins-regular fs-1"><i class="fa fa-map-marker ">&nbsp;&nbsp;Remote</i>
                </p>
                <p class="p-btn mb-4 font-poppins-regular fs-1"><i class="fa fa-clock-o">&nbsp;&nbsp;Full time</i></p>

            </div>
            <!--            dupilcate start for small screen-->
            <div class="fl-x-cc">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-4 plain-button mt-2 mb-4 d-lg-none"></btn>
                    </div>
<!--            duplicate end-->
        </div>
        <div class="bt-1 bb-1">
            <p class="font-weight-bold fs-1 blue mt-3">Collection Executive</p>
            <div class="row">
                <div class="col-12 col-lg-9">
                    <p class="fs--1"> Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Itaque modi quas repellendus ut veniam. Nisi? </p>
                </div>
                <div class="col-3 col-lg-3">
                    <div class="fl-x-br">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-3 plain-button d-sm-none "></btn>
                    </div>
                </div>
            </div>
            <div class="row pl-3 pl-lg-0">

                <p class="p-btn mr-3 ml-lg-3 mb-4 font-poppins-regular fs-1"><i class="fa fa-map-marker ">&nbsp;&nbsp;Remote</i>
                </p>
                <p class="p-btn mb-4 font-poppins-regular fs-1"><i class="fa fa-clock-o">&nbsp;&nbsp;Full time</i></p>

            </div>
             <!--            dupilcate start for small screen-->
            <div class="fl-x-cc">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-4 plain-button mt-2 mb-4 d-lg-none"></btn>
                    </div>
<!--            duplicate end-->
        </div>
        <div class="bt-1 bb-1">
            <p class="font-weight-bold fs-1 blue mt-3">Collection Executive</p>
            <div class="row">
                <div class="col-12 col-lg-9">
                    <p class="fs--1"> Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Itaque modi quas repellendus ut veniam. Nisi? </p>
                </div>
                <div class="col-3 col-lg-3">
                    <div class="fl-x-br">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-3 plain-button d-sm-none "></btn>
                    </div>
                </div>
            </div>
            <div class="row pl-3 pl-lg-0">

                <p class="p-btn mr-3 ml-lg-3 mb-4 font-poppins-regular fs-1"><i class="fa fa-map-marker ">&nbsp;&nbsp;Remote</i>
                </p>
                <p class="p-btn mb-4 font-poppins-regular fs-1"><i class="fa fa-clock-o">&nbsp;&nbsp;Full time</i></p>

            </div>
             <!--            dupilcate start for small screen-->
            <div class="fl-x-cc">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-4 plain-button mt-2 mb-4 d-lg-none"></btn>
                    </div>
<!--            duplicate end-->
        </div>
        <div class="bt-1 bb-1">
            <p class="font-weight-bold fs-1 blue mt-3">Collection Executive</p>
            <div class="row">
                <div class="col-12 col-lg-9">
                    <p class="fs--1"> Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Itaque modi quas repellendus ut veniam. Nisi? </p>
                </div>
                <div class="col-3 col-lg-3">
                    <div class="fl-x-br">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-3 plain-button d-sm-none "></btn>
                    </div>
                </div>
            </div>
            <div class="row pl-3 pl-lg-0">

                <p class="p-btn mr-3 ml-lg-3 mb-4 font-poppins-regular fs-1"><i class="fa fa-map-marker ">&nbsp;&nbsp;Remote</i>
                </p>
                <p class="p-btn mb-4 font-poppins-regular fs-1"><i class="fa fa-clock-o">&nbsp;&nbsp;Full time</i></p>

            </div>
             <!--            dupilcate start for small screen-->
            <div class="fl-x-cc">
                        <btn size="sm" design="basic-b" text="Apply Now" border-radius="5"
                             class="px-4 plain-button mt-2 mb-4 d-lg-none"></btn>
                    </div>
<!--            duplicate end-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'View-All'
};
</script>

<style scoped>
.blue {
    color: #222D65;
}

.p-btn {
    border: 1px solid #222D65;
    border-radius: 16px;
    font-size: 14px;
    color: #222D65;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
}

i {
    color: #222D65;
}
@media(max-width:992px){
    .p-btn {
    border: 1px solid #222D65;
    border-radius: 16px;
    font-size: 12px;
    color: #222D65;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
}
}
</style>
