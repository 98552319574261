<template>
    <div class="py-7">
        <div class="container">
            <div class="row">

                <div class="col-12 col-lg-12">
                    <!--Tabs-->
                    <div>

                        <div title="Faq" class="">
                            <div class="col-12 col-lg-11">
                                <!--                                <img src="../../assets/web/course-offered/bgi.jpg" class="img-fluid" alt="">-->
                                <p class="font-poppins-bold fs-4 fl-x-cc mb-3 text-primary ">
                                    Frequently Asked Questions</p>
                                <p class="font-poppins-regular fs--1 text-primary fl-x-cc mb-4 mb-lg-5">Lorem ipsum dolor sit amet,
                                    consectetur adipisicing elit.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!--                <div class="row">-->

                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="Lorem ipsum dolor sit amet, consectetur adipisicing ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">

                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="Lorem ipsum dolor sit amet, consectetur adipisicing ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">

                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="Lorem ipsum dolor sit amet, consectetur adipisicing ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">

                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="Lorem ipsum dolor sit amet, consectetur adipisicing ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">

                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="Lorem ipsum dolor sit amet, consectetur adipisicing ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">

                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="Lorem ipsum dolor sit amet, consectetur adipisicing ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">

                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="Lorem ipsum dolor sit amet, consectetur adipisicing ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">

                                    <p class="font-lato-regular fs--1">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-11 pl-lg-0 ">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="dolor sit amet, consectetur adipisicing elit ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">
                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="debitis dolor doloremque ducimus eos et libero magnam nam neque no ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">

                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>

                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="doloremque ducimus eos et libero magnam name?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">
                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
                <div class="col-12 col-lg-11 pl-lg-0">
                    <div class="fl-y fl-a-s">

                        <base-accordion color="white" class="bb-white"
                                        heading="doloremque ducimus eos et libero magnam nam magnam ?">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">
                                    <p class="font-lato-regular fs--1 text-primary">Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit.
                                        A ab debitis dolor doloremque ducimus eos et libero magnam nam neque non
                                        optio quasi, quia quos
                                        recusandae rem saepe suscipit voluptatem!</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>

<!--                <div class="min-rob">-->
<!--                <img src="../../assets/web/Images/robo.jpg">-->
<!--            </div>-->

            </div>

        </div>

    </div>
</template>

<script>
import BaseAccordion from '@components/ui/BaseAccordion';

export default {
    name: 'Faqq',
    components: { BaseAccordion }
};
</script>

<style scoped lang="scss">
.im-2 img {
    height: 150px;
    width: 150px;
}
.white{
    background-color: white;
}

.min-rob img {
    position: absolute;
    width: 80px;
    height: 80px;
    //top: 846px;
    right: 45px;
    bottom: 0px;
    border-radius: 50%;
}

.bb-white {
    border-bottom: 9px solid white;
}

.custom-ul-list {
    list-style: none;

    li {
        position: relative;

        &:before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: var(--color-primary);
            border-radius: 50%;
            //transform: translateY(-40%);
            top: 9px;
            left: 0;
            position: absolute;
        }

        &:after {
            content: "";
            position: absolute;
            left: 2px;
            top: 17px;
            width: 1px;
            height: 100%;
            background-color: rgba(173, 189, 183, 0.5);
        }
    }
}

.fss {
    font-size: 22px !important;
}

.pos-a-left-ol {
    @media (min-width: 992px) {
        position: absolute;
        width: 70%;
        left: -4rem;
        top: -5rem;
    }

}
</style>
