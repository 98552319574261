<template>
    <div class="py-5 bg-gradient-1">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <base-heading-with-info class="mb-4" title="Our Features"
                                            info="There are many variations of passages of Lorem Ipsum available alteration in some form, by injected"
                    >
                    </base-heading-with-info>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-lg-3 col-md-6">
                            <article-card
                                id="124"
                                hour="03"
                                heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                :img="featureImg1"
                                categ="category"
                            ></article-card>
                        </div>
                        <div class="col-12 col-lg-3 col-md-6">
                            <article-card
                                id="124"
                                hour="03"
                                heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                :img="featureImg1"
                                categ="category"
                            ></article-card>
                        </div>
                        <div class="col-12 col-lg-3 col-md-6">
                            <article-card
                                id="124"
                                hour="03"
                                heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                :img="featureImg1"
                                categ="category"
                            ></article-card>
                        </div>
                        <div class="col-12 col-lg-3 col-md-6">
                            <article-card
                                id="124"
                                hour="03"
                                heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                                content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                                :img="featureImg1"
                                categ="category"
                            ></article-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArticleCard from '@components/ui/ArticleCard';
import featureImg1 from '../../../assets/web/about/feature-img-1.png';

export default {
    name: 'OurFeatures',
    components: { ArticleCard },
    data () {
        return {
            featureImg1: featureImg1
        };
    }
};
</script>

<style scoped>

</style>
