<template>
    <div class="counterbg py-6">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-3 col-md-6">
                    <base-counter to="352" heading="People Connected" count="5156" class=""
                                  icon="">
                    </base-counter>
                </div>
                <div class="col-12 col-lg-3 col-md-6">
                    <base-counter to="352" heading="People Connected" count="3549" class=""
                                  icon="">
                    </base-counter>
                </div>
                <div class="col-12 col-lg-3 col-md-6">
                    <base-counter to="352" heading="People Connected" count="43" class=""
                                  icon="">
                    </base-counter>
                </div>
                <div class="col-12 col-lg-3 col-md-6">
                    <base-counter to="352" heading="People Connected" count="08" class=""
                                  icon="">
                    </base-counter>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseCounter from '../../ui/BaseCounter';
export default {
    name: 'HomeProjectCounter',
    components: { BaseCounter }
};
</script>

<style scoped>
.counterbg {
    background-image: url("../../../assets/web/Images/Gradient/blue-gradient.png");
    background-size: cover;
}
</style>
