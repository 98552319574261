<template>
    <div class="">
        <base-banner :img="homeBannerImg" class="d-sm-none" height="full" heading="" heading-align="center" :overlay="true">
            <template #default>
                <div class="text-primary w-lg-60">
                    <p class="fs-lg-1">Welcome to Advent Credit Solutions</p>
                    <h3 class="font-poppins-regular font-weight-600">Accelerate your Debt Collection</h3>
                    <p class="mt-3 font-weight-500 text-primary"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste,
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste,
                        omnis. </p>
                    <btn design="basic-b" text="Get Started" size="lg" class="pr-4 pl-3 lego-btn-arrow-right"
                         border-radius="4"></btn>
                </div>
            </template>
        </base-banner>
        <base-banner :img="homeBannerImg" class="d-lg-none" height="full" heading="" heading-align="bottom" :overlay="true">
            <template #default>
                <div class="text-primary w-lg-60">
                    <p class="fs-lg-1">Welcome to Advent Credit Solutions</p>
                    <h3 class="font-poppins-semibold">Accelerate your Debt Collection</h3>
                    <p class="mt-3 font-poppins-medium"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste,
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste,
                        omnis. </p>
                    <btn design="basic-b" text="Get Started" size="lg" class="pr-4 pl-3 lego-btn-arrow-right"
                         border-radius="4"></btn>
                </div>
            </template>
        </base-banner>
        <div id="solution-mildstones">
            <solution-mildstones></solution-mildstones>
        </div>
        <div id="sector-bindings">
            <sector-bindings></sector-bindings>
        </div>
        <div id="experienced-professional-team">
            <experienced-professional-team></experienced-professional-team>
        </div>
        <div id="home-project-counter">
            <home-project-counter></home-project-counter>
        </div>
        <div id="our-clients">
            <our-clients></our-clients>
        </div>
        <div id="testimonials">
            <testimonials-section></testimonials-section>
        </div>
        <div id="latest-news">
            <latest-news-section></latest-news-section>
        </div>
        <div id="premium-membership">
            <premium-membership></premium-membership>
        </div>
        <div id="top-foote">
            <top-footer-image></top-footer-image>
        </div>
    </div>

</template>

<script>
// import BaseHeadingWithInfo from '@components/ui/BaseHeadingWithInfo';
import memberImg1 from '../assets/web/home/teams/member-1.jpg';
import testimonialImg1 from '../assets/web/home/testimonials/testimonial-person-1.png';
import testimonialImg2 from '../assets/web/home/testimonials/testimonial-person-2.png';
import testimonialImg3 from '../assets/web/home/testimonials/testimonial-person-3.png';
import newsImg1 from '../assets/web/home/news/news-img-1.png';
import homeBannerImg from '../assets/web/home/home-banner-with-gradient.png';
// import MemberCard from '@components/home/MemberCard';
// import TestimonialCard from '@components/home/TestimonialCard';
// import NewsCard from '@components/home/NewsCard';
import BaseBanner from '@components/ui/BaseBanner';
import SolutionMildstones from '@components/home/sections/SolutionMildstones';
import SectorBindings from '@components/home/sections/SectorBindings';
import ExperiencedProfessionalTeam from '@components/home/sections/ExperiencedProfessionalTeam';
import HomeProjectCounter from '@components/home/sections/HomeProjectCounter';
import OurClients from '@components/home/sections/OurClients';
import TestimonialsSection from '@components/home/sections/testimonials/TestimonialsSection';
import LatestNewsSection from '@components/home/sections/latest-news/LatestNewsSection';
import PremiumMembership from '@components/home/sections/PremiumMembership';
import TopFooterImage from '@components/home/sections/TopFooterImage';

export default {
    name: 'Home',
    components: { TopFooterImage, PremiumMembership, LatestNewsSection, TestimonialsSection, OurClients, HomeProjectCounter, ExperiencedProfessionalTeam, SectorBindings, SolutionMildstones, BaseBanner },
    data () {
        return {
            memberImg1: memberImg1,
            testimonialImg1: testimonialImg1,
            testimonialImg2: testimonialImg2,
            testimonialImg3: testimonialImg3,
            newsImg1: newsImg1,
            homeBannerImg: homeBannerImg
        };
    }
};

</script>
<style scoped>

</style>
