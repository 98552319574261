<template>
    <div>
        <swiper class="swiper home-slider swiper-basic-1 pb-6" data-mousewheel="1" data-parallax="1"
                :options="swiperOption">
            <swiper-slide class="px-3">
                <article-card
                    id="124"
                    hour="03"
                    heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                    content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                    :img="newsImg1"
                    categ="category"
                ></article-card>
            </swiper-slide>
            <swiper-slide class="px-3">
                <article-card
                    id="124"
                    hour="03"
                    heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                    content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                    :img="newsImg2"
                    categ="category"
                ></article-card>
            </swiper-slide>
            <swiper-slide class="px-3">
                <article-card
                    id="124"
                    hour="03"
                    heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                    content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                    :img="newsImg3"
                    categ="category"
                ></article-card>
            </swiper-slide>
            <swiper-slide class="px-3">
                <article-card
                    id="124"
                    hour="03"
                    heading="Buy Now, Pay Later: Is It Another Debt Trap?"
                    content="Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor  mgnhe incididunt ut labore et dolore magna aliqua. Ut enim ad minim "
                    :img="newsImg3"
                    categ="category"
                ></article-card>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
    </div>
</template>
<script> import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ArticleCard from '@components/ui/ArticleCard';
import newsImg1 from '../../../../assets/web/home/news/news-img-1.png';
import newsImg2 from '../../../../assets/web/home/news/news-img-2.png';
import newsImg3 from '../../../../assets/web/home/news/news-img-3.png';

export default {
    name: 'LatestNewsSwiper',
    components: { ArticleCard, Swiper, SwiperSlide },
    data () {
        return {
            newsImg1: newsImg1,
            newsImg2: newsImg2,
            newsImg3: newsImg3,
            swiperOption: {
                autoHeight: true,
                slidesPerView: 3,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                mousewheel: false,
                parallax: true,
                centeredSlides: false,
                // autoplay: true,
                autoplay: { delay: 8000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: {
                        speed: 1500,
                        slidesPerView: 3,
                        spaceBetween: 0
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                        speed: 800
                    },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }

            }
        };
    }
};
</script>
}
<style scoped> .item {
    width: 100%;
    display: flex;
    align-items: center;
}

.home-banner-icon {
    display: inline-block;
}

.home-banner-icon img {
    width: 1.2rem;
    margin-left: 20px;
}

.home-slider h1 {
    line-height: 40px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: unset;
    padding: 3px 25px;
    background: #2929293b;
    border-radius: 15px;
}

@media (min-width: 992px) {

    .home-banner-icon img {
        width: 2rem;
        margin-left: 20px;
    }
} </style>
