<template>
    <div>
        <div>
            <career-banner></career-banner>
        </div>
        <div>
            <apply-now></apply-now>
        </div>
        <div>
            <core-value></core-value>
        </div>
        <div>
            <core-value-card></core-value-card>
        </div>
        <div>
            <open-position></open-position>
        </div>
    </div>
</template>
<script>
import CoreValueCard from '@components/Career/CoreValue-Card';
import CoreValue from '@components/Career/CoreValue';
import CareerBanner from '@components/Career/Career-Banner';
import ApplyNow from '@components/Career/apply-now';
import OpenPosition from '@components/Career/Open-Position';

export default {
    name: 'Career',
    components: { OpenPosition, ApplyNow, CareerBanner, CoreValue, CoreValueCard }
};
</script>

<style scoped>

</style>
