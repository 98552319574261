<template>
    <div>
        <div class="container">
            <div class="row pt-1 pt-lg-5 pb-lg-5 pb-5 pb-md-8">
                <div class="col-lg-2 order-2 order-lg-1">
                    <div class="pic pt-7 pt-lg-1 pb-md-9">
                        <img class="blue" src="../../assets/web/about/Rectangle-primary.png">
                        <img class="grey" src="../../assets/web/about/Rectangle-gray-dummy.png">
                    </div>
                </div>
                <div class="col-lg-10 order-1 order-lg-2">
                    <div class="p-rr section-1  ">
                        <div class="fl-x-bl "><img src="../../assets/web/icons/Vectorman.png" class="mr-3 mb-3" alt="">
                            <p class=" fs-lg-4 text-primary mb-0  font-poppins-semibold text-uppercase mb-3">Your Name
                                Here</p></div>
                        <div class="fl-x-bl "><img src="../../assets/web/icons/suitcase4.png" class="mr-3 mb-4" alt="">
                            <p class=" fs-lg-1 text-primary mb-0  font-poppins-semibold text-uppercase mb-4">Job
                                Description</p></div>
                        <p class="mb-2 fs-1 ">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid
                            aspernatur
                            assumenda aut
                            atque
                            commodi, earum est laborum nemo nesciunt odit, quam qui rem ut
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid aspernatur
                            assumenda
                        </p>
                        <div class="row">
                            <div class="col-12">
                                <div class="social-i pl-5 pl-md-0 pl-lg-0 mt-4">
                                    <img class="right" src="../../assets/web/icons/fb.png">
                                    <img class="right" src="../../assets/web/icons/twitter.png">
                                    <img class="right" src="../../assets/web/icons/linkedin.png">
                                    <img class="right" src="../../assets/web/icons/mail.png">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Team-A'

};
</script>

<style scoped lang="scss">
.blue {

    position: absolute;
}

.grey {
    position: relative;
    /*z-index: 1px;*/
}

.right {
    margin-right: 34px;
}

.section-1 {
    background-color: #f4f4f4;
    /*border-radius: 186px 0px 0px 182px / 182px 23px 26px 176px;*/
    border-radius: 209px 203px 2px 0px / 195px 186px 136px 150px;
    box-shadow: -2px 2px 5px 3px rgba(0, 0, 0, 0.25);

    @media (min-width: 992px) {
        max-height: 370px;
        border-radius: 0px 189px 197px 0px / 25px 182px 179px 150px;

    }
    //@media (min-width: 768px) and (max-width: 1024px) {
    //    border-radius: 276px 256px 22px 66px / 150px 145px 0px 1px;
    //}
}

.social-i img {
    height: 14px;
    width: 14px;
    border-radius: 0px;

}

.social-i {
    position: absolute;
}

.pic img {

    position: absolute;
    left: 0;
    top: -14.1875rem;
    width: 100%;
    height: auto;
    @media (min-width: 768px) and (max-width: 1024px) {
        top: -25.1875rem;
    }

    @media(min-width: 992px) {
        top: -25px;
        height: 408px !important;
        width: 355px !important;
    }
    z-index: 3;
}

.p-rr {
    padding: 6rem 2rem 21rem 4rem;
    @media (min-width: 768px) and (max-width: 1024px) {
        padding-right: 7rem;
        padding-left: 7rem;
        padding-bottom: 36rem;
        padding-top: 10rem;
    }

    @media (min-width: 992px) {
        padding-right: 8rem;
        padding-left: 14rem;
        padding-bottom: 6rem;
        padding-top: 6rem;
    }
}

</style>
