<template>
<div class="container">
    <div class="col-12 col-lg-12 mb-4">
<iframe  class="w-100 py-4 " height="400" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d251491.58693690525!2d76.16820079757136!3d9.970845393201529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d08f976f3a9%3A0xe9cdb444f06ed454!2sErnakulam%2C%20Kerala!5e0!3m2!1sen!2sin!4v1670046851265!5m2!1sen!2sin"
         style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>
</template>

<script>
export default {
    name: 'contact-map'
};
</script>

<style scoped>

</style>
