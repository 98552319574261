<template>
    <div>
        <div class="container">
            <div class="row pt-1 pt-lg-5 pb-lg-5 pb-5 pb-md-7">
                <div class="col-lg-2 order-1 order-lg-1">
                    <div class="pic pt-7 pt-lg-1 pb-md-9">
                        <img class="blue " src="../../assets/web/about/Rectangle-primary.png">
                        <img class="grey " src="../../assets/web/about/Rectangle-gray-dummy.png">
                    </div>
                </div>
                <div class="col-lg-10 order-2 order-lg-2 " align="right">
                    <div class="p-rr section-1  ">

                        <p class=" fs-lg-4 text-primary mb-0  font-poppins-semibold text-uppercase mb-3">Why Choose
                            Us?</p>
                        <p class="mb-2 fs-1 ">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid
                            aspernatur
                            assumenda aut
                            atqueknj nj
                            commodi, earum est laborum nemo nesciunt odit, quam qui rem ut
                            Lorem ipsum dolor sit amet,</p>
                        <p> consectetur adipisicing elit. Aliquam aliquid aspernatur
                            Lorem ipsum dolor sit amet, consectetur adipisicing.
                            Lorem ipsum dolor sit amet.
                        </p>
                        <div class="row">
                            <div class="col-12">
                                <btn design="basic-b" text="Contact Us" size="lg"
                                     class="pr-4 ml-lg-9 lego-btn-arrow-right"
                                     border-radius="4"></btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Team-f'

};
</script>

<style scoped lang="scss">
.blue {

    position: absolute;
}

.grey {
    position: relative;
    /*z-index: 1px;*/
}

.right {
    margin-right: 34px;
}

.section-1 {
    background-color: #f4f4f4;
    /*border-radius: 186px 0px 0px 182px / 182px 23px 26px 176px;*/
    //border-radius: 209px 203px 2px 0px / 195px 186px 136px 150px;
    border-radius: 0px 0px 400px 399px / 280px 137px 300px 300px;
    box-shadow: -2px 2px 5px 3px rgba(0, 0, 0, 0.25);

    @media (min-width: 992px) {
        max-height: 370px;
        border-radius: 0px 189px 197px 0px / 25px 182px 179px 150px;

    }
    //@media (min-width: 768px) and (max-width: 1024px) {
    //    border-radius: 276px 256px 22px 66px / 150px 145px 0px 1px;
    //}
}

.social-i img {
    height: 14px;
    width: 14px;
    border-radius: 0px;

}

.mt-150 {
    margin-top: 150px;
    @media (min-width: 992px) {
        margin-top: 0px;
    }
}

.social-i {
    position: absolute;
}

.pic img {

    position: absolute;
    left: 0;
    top: -6.1875rem;
    width: 100%;
    height: auto;
    @media (min-width: 768px) and (max-width: 1024px) {
        top: -2.1875rem;
    }

    @media(min-width: 992px) {
        top: -27px;
        height: 414px !important;
        width: 360px !important;
    }
    z-index: 3;
}

.p-rr {
    padding: 14rem 4rem 8rem 2rem;
    @media (min-width: 768px) and (max-width: 1024px) {
        padding-right: 7rem;
        padding-left: 7rem;
        padding-bottom: 9rem;
        padding-top: 30rem;
    }

    @media (min-width: 992px) {
        padding-right: 8rem;
        padding-left: 14rem;
        padding-bottom: 6rem;
        padding-top: 6rem;
    }
}

</style>
