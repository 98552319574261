<template>
    <div class="">
<div class="contactUs-banner">
<!--    <div class="row">-->
<!--      <div class="col-12 col-lg-12">-->
<!--        <p></p>-->
<!--      </div>-->
<!--    </div>-->
  </div>

    </div>
</template>

<script>
export default {
    name: 'Contact-Banner'
};
</script>

<style scoped>
.contactUs-banner{
  background-image: url("../../assets/web/Images/Gradient/Contact-Us-image.png");
  min-height: 45vh;
  background-size: cover;
  /*background-position: 0% 50%;*/
}
@media(max-width:750px){
     .contactUs-banner {
        padding:0;
        background-position: center 100%;
        background-size: cover;
        min-height: 35vh;
        width:100%;
   }
    }
</style>
