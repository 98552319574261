<template>
    <div>
        <swiper class="swiper home-slider swiper-basic-1 pb-6" data-mousewheel="1" data-parallax="1"
                :options="swiperOption">
            <swiper-slide class="px-3 px-lg-0">
                <img src="../../../assets/web/home/sector-bindings/sectors-bindings-img-1.png" class="img-fluid" alt="">
            </swiper-slide>
            <swiper-slide class="px-3 px-lg-0">
                <img src="../../../assets/web/home/sector-bindings/sectors-bindings-img-1.png" class="img-fluid" alt="">
            </swiper-slide>
            <swiper-slide class="px-3 px-lg-0">
                <img src="../../../assets/web/home/sector-bindings/sectors-bindings-img-1.png" class="img-fluid" alt="">
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
    </div>
</template>
<script> import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import img1 from '../../assets/web/landing/our-courses/course-1.jpg';
// import img2 from '../../assets/web/landing/our-courses/course-2.jpg';
// import img3 from '../../assets/web/landing/our-courses/course-3.jpg';
// import CourseCard from '../../components/home/CourseCard';
// import img4 from '../../assets/web/landing/our-courses/farm-animal-welfare.png';
// import img5 from '../../assets/web/landing/our-courses/istockphoto-gr.png';
// import img6 from '../../assets/web/landing/our-courses/istockphoto-bl.png';

export default {
    name: 'SectorBindingsSwiper',
    components: { Swiper, SwiperSlide },
    data () {
        return {
            // img4: img4,
            // img5: img5,
            // img6: img6,
            swiperOption: {
                autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                mousewheel: false,
                parallax: true,
                centeredSlides: false,
                // autoplay: true,
                autoplay: { delay: 8000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: {
                        speed: 1500
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 0,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                        speed: 800
                    },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }

            }
        };
    }
};
</script>
}
<style scoped> .item {
    width: 100%;
    display: flex;
    align-items: center;
}

.home-banner-icon {
    display: inline-block;
}

.home-banner-icon img {
    width: 1.2rem;
    margin-left: 20px;
}

.home-slider h1 {
    line-height: 40px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: unset;
    padding: 3px 25px;
    background: #2929293b;
    border-radius: 15px;
}

@media (min-width: 992px) {

    .home-banner-icon img {
        width: 2rem;
        margin-left: 20px;
    }
} </style>
