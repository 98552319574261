<template>
    <div class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <base-heading-with-info class="mb-4" title="About Our Company"
                                            info="There are many variations of passages of Lorem Ipsum available alteration in some form, by injected"
                    >
                    </base-heading-with-info>
                    <p class="text-center px-lg-9">
                        <span class="font-poppins-bold">ADVENT</span> is a technology driven platform providing Collection Services by submitting your claims
                        online. We provide services to match the various needs of our customers related to their normal
                        as well as long outstanding, difficult to realise claims thereby reducing their stress on
                        collection and helps increase their focus on business development.

                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutOurCompany'
};
</script>

<style scoped>

</style>
