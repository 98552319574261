<template>
    <div class="background-grey">
        <div class="container ">
        <div class="row">
            <div class="col-12 col-lg-12">

                    <p class="font-poppins-semibold fs-2 mt-4">About Advent</p>
                    <p class="mt-3  font-poppins-regular fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                        Adipisci debitis delectus itaque
                        iusto tempore. Deserunt dolor ea eligendi facere impedit Lorem ipsum dolor sit amet, consectetur
                        adipisicing elit. Eveniet inventore ipsam laboriosam nulla perspiciatis
                        quisquam tempore unde. Dicta, placeat sint.
                        in, ipsum maiores pariatur recusandae tempore, tenetur voluptate voluptatibus! Ipsam?
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus provident reprehenderit sequi ut
                        voluptas!
                        Consequuntur eligendi nemo non numquam odit.</p>
                    <p class="mt-4 font-poppins-regular fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        A aut
                        commodi
                        culpa cum dignissimos dolores dolorum ducimus eligendi eos error et fuga fugit
                        harum, illo impedit ipsa iusto, laudantium libero natus nemo neque non nostrum odio
                        odit, omnis quibusdam quos rem repellat sapiente sit
                        tempora temporibus unde voluptas? Fugiat, non.</p>

                    <p class="font-poppins-semibold fs-2 mt-5">Responsibilities</p>
                    <ul class="ml-4 fs--1 font-poppins-regular">
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?ipsum dolor
                            sit amet, consectetur adipisicing elit. Molestiae,it amet, consectetur
                        </li>

                    </ul>

                    <p class="font-poppins-semibold fs-2 mt-5">Skills and Experience</p>
                    <ul class="ml-4 fs--1 font-poppins-regular">
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, consectetur , voluptates
                        </li>
                        <li>Lorem sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor s Molestiae, voluptates
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, cipisicing elit. Molestiae, voluptates
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates
                        </li>

                    </ul>
                    <p class="font-poppins-semibold fs-2 mt-5">Why Advent</p>
                    <ul class="ml-4 fs--1 font-poppins-regular">
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates?Lorem ipsum
                            dolor sit amet, consectetur adipisicing elit. Molestiae, voluptates
                        </li>

                    </ul>
                    <p class="fs--1 font-poppins-regular mt-5"><i>Lorem Lorem ipsum dolor sit amet,
                        consectetur adipisicing elit. Ab accusamus aliquam, aut doloribus earum excepturi
                        ipsum iure nesciunt, officiis perferendis quibusdam reiciendis totam vel.
                        Ab commodi deleniti nobis quae quas. ipsum dolor sit amet, consectetur adipisicing elit
                        . Aliquam animi nisi quaerat sit ut. Blanditiis deleniti explicabo harum natus, quae
                        quidem recusandae vitae! Assumenda blanditiis incidunt obcaecati, optio pariatur
                        similique.i nisi quaerat sit ut. Blanditiis deleniti explicabo harum natus, quae
                        quidem recusandae vitae! Assumenda bl</i></p>

                    <div class="fl-x-cc mb-5">
                        <btn size="sm" text="Apply for this job" border-radius="5"
                             class="px-3 plain-button mt-4"></btn>
                    </div>
<!--                    <div class="min-rob">-->
<!--                        <img src="../../assets/web/Images/robo.jpg">-->
<!--                    </div>-->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'About-Career'
};
</script>

<style scoped>
.background-grey {
    background-color: #f4f4f4;
}

.min-rob img {
    position: absolute;
    width: 80px;
    height: 80px;

    right: 76px;
    bottom: -15px;
    border-radius: 50%;
}
</style>
