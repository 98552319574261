<template>
<div>
    <div class="">

        <collection-executive></collection-executive>
    </div>
    <div>
        <about-career></about-career>
    </div>
</div>
</template>

<script>
import CollectionExecutive from '@components/Career-inner-page/Collection-Executive';
import AboutCareer from '@components/Career-inner-page/About-Career';
export default {
    name: 'Career-InnerPage',
    components: { AboutCareer, CollectionExecutive }
};
</script>

<style scoped>

</style>
