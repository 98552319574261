<template>
<div>
    <div>
       <career-header></career-header>
    </div>
    <div>
        <career-input-form></career-input-form>
    </div>
</div>
</template>

<script>
import CareerInputForm from '@components/Career-Form/Career-Input-Form';
import CareerHeader from '@components/Career-Form/Career-header';
export default {
    name: 'Career-Form',
    components: { CareerHeader, CareerInputForm }
};
</script>

<style scoped>

</style>
