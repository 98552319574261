<template>
    <div class="mb--1re">
        <div class="fl-x fl-j-c c-res-mb--15re">
            <img src="../../../assets/web/Images/Dots.png" class="img-fluid" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopFooterImage'
};
</script>

<style scoped lang="scss">
.c-res-mb--15re {
    margin-bottom: -5rem;
    @media (min-width: 992px) {
        margin-bottom: -15rem;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: -9rem;
    }
}
</style>
