<template>
    <div class="pb-4 bg-2 mb-4 c-bs-2" @click="$router.push('/news/details')">
        <div class="card-min-h"
             :style="{'background-image':'url('+ img +')', 'background-size':'cover', 'background-repeat':'no-repeat', 'background-position':'0% 0%'}">
        </div>
        <div class="ml-3 mt-3">
            <h6 class="font-weight-bold fs-lg-1 pr-lg-4">{{ heading }}</h6>
            <p class="fs--1 mt-4 font-inter-regular text-3 align-justify pr-2"> {{ content }} </p>
            <span class="text-primary font-poppins-semibold text-uppercase fs--1 mr-2">{{ categ }}</span>
        </div>
    </div>
</template>
<script>

export default {
    name: 'ArticleCard',
    components: {},
    props: {
        id: {
            type: String,
            default: ''
        },
        heading: {
            type: String,
            default: ''
        },
        route: {
            type: String,
            default: ''
        },
        hour: {
            type: String,
            default: ''
        },
        categ: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        author: {
            type: String,
            default: ''
        },
        img: {
            type: String
        },
        content: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }
    }
};
</script>
<style lang="scss" scoped>
.card-min-h {
    position: relative;
    height: 12rem;

    img {
        width: 100% !important;
        height: 100% !important;
    }
}

.border-left {
    border-left: 1px solid var(--text-2);
}
.c-bs-2{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
