<template>
    <div class="bg-white">
        <div class="container">
            <div class="row">
                <div class=" col-12 col-lg-12">
                    <div class="sec-1 mt-4 mb-2">
                        <p class="fl-x-cc font-weight-bold fs-lg-1 fs-2 blue mb-2">CAREERS</p>
                        <p class="fl-x-cc fs--1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            Consequuntur cumque ex facilis.</p>
                        <div class="fl-x-cc mb-4">
                            <btn size="sm" text="Apply Now" border-radius="5" class="px-3"></btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Apply-now'
};
</script>

<style scoped>
.blue {
    color: darkblue;
}
</style>
