<template>
    <div>
        <div class="p-3">
            <div class="fl-x-cc py-2 mb-4">
                <img src="../../assets/web/Images/checked-tick.png">
            </div>
            <p class="content font-poppins-semibold fs-3 c-fl-j-e mb-4" align="center">Your Application Is Succesfully
                Submitted</p>
            <p class="content font-poppins-regular fs--2" align="center">We'll contact you when a desicission is
                made</p>
            <div class="fl-x-cc">
                <btn design="" class="btn-bg-white px-7 p7-3 mb-5 mt-4 font-poppins-medium" border-radius="4" text="Close"></btn>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Form-Success-Modal'
};
</script>

<style scoped>
.content {
    color: white;
}
</style>
