<template>
    <div>
        <base-form-control v-bind="formControlAttributes" @clear-clicked="clear" class="input-bg">
            <slot name="prepend"></slot>
            <label class="form-file">
                <span v-if="text === ''" class="text-white">Attach Resume/Cv</span>
                <input v-on="listeners" @change="updateValue" :disabled="disabled" type="file"/>
            </label>
            <slot name="append"></slot>
        </base-form-control>
        <span v-text="text" class="fs--2"></span>

    </div>

</template>

<script>
import BaseFormControl from './BaseFormControl';
import BaseInput from './BaseInput';

export default {
    name: 'custom-file-input',
    components: { BaseFormControl },
    extends: BaseInput,
    data () {
        return {
            text: ''
        };
    },
    methods: {
        updateValue (evt) {
            const target = evt.target;
            const files = Array.from(target.files);
            this.files = files;
            const len = files.length;
            if (len === 0) {
                return;
            }

            if (len === 1) {
                const file = files[0];
                this.text = file.name;
            } else if (len > 1) {
                this.text = `${len} files selected`;
            }

            this.$emit('input', this.files);
        }
    }

};
</script>
<style scoped lang="scss">
.input-bg {
    background-color: #222D65;
    color: white !important;
    border-radius: 6px;
}
</style>
