<template>
    <div class="py-5 pt-52 bg-gradient-3">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <base-heading-with-info class="mb-5" title="Our Clients"
                                            info="There are many variations of passages of Lorem Ipsum available alteration in some form, by injected"
                    >
                    </base-heading-with-info>
                </div>
                <div class="col-12">
                    <div class="px-4">
                        <div class="row">
                            <div class="col-12 col-lg-3 col-md-6 pos-r">
                                <div class="fl-x fl-j-c fl-x-cc px-2 mb-5 mb-lg-0 pos-r">
                                    <div class="line-right"></div>
                                    <img src="../../../assets/web/home/clients/client-1.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 col-md-6">
                                <div class="fl-x fl-j-c fl-x-cc px-2 mb-5 mb-lg-0 pos-r pr-lg-4">
                                    <div class="line-right"></div>
                                    <img src="../../../assets/web/home/clients/client-2.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 col-md-6">
                                <div class="fl-x fl-j-c fl-x-cc px-2 mb-5 mb-lg-0 pos-r pr-lg-4">
                                    <div class="line-right"></div>
                                    <img src="../../../assets/web/home/clients/client-3.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 col-md-6">
                                <div class="fl-x fl-x-res px-2">
                                    <img src="../../../assets/web/home/clients/client-4.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurClients'
};
</script>

<style scoped>

</style>
