<template>
    <div class="z-index-2 cursor-pointer b-1 bs-3 m-2 border-r-0 c-bs-1"
         :class="{'pt-5 pb-3': size === 'sm', 'pt-51 pb-31': size === 'lg'}">
        <div class="text-center">
<!--            <img src="../../assets/web/home/icons/quote-left-alter-48.png" alt="">-->
            <img src="../../assets/web/home/icons/quote.png" alt="">
        </div>
        <div class="text-center bottom-tag px-4 mt-3">
            <p class="text-2 fs-lg--1" :class="{'font-poppins-medium': size === 'lg'}">{{ info }}</p>
        </div>
        <div class="fl-y fl-a-c bg-2 mt-5 h-100 px-3" :class="{'w-80':size ==='sm'}">
            <img :src="img" alt="" class="border-rounded mb-2 img-fluid"
                 :class="{'w-25p':size ==='sm','w-33p':size === 'lg'}">
            <p class="text-5" m-0>{{ name }}</p>
            <div>
                <slot name="details"></slot>
            </div>
            <div>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script> export default {
    name: 'TestimonialCard',
    props: {
        title: { type: String, default: 'Title' },
        name: { type: String, default: '' },
        designation: { type: String, default: '' },
        img: { type: String },
        size: { type: String, default: 'sm' },
        info: {
            type: String
        },
        type: { type: String, default: '' },
        backRouteName: { type: String, default: 'Home' },
        detailsPath: { type: String, default: '/' }
    },
    methods: {
        openMemberPage () {
            if (this.size === 'sm') {
                const routePath = this.detailsPath + this.name.replace(/\s+/g, '');
                this.$router.push({ path: routePath, params: { name: this.name.trim() } });
            }
        }
    }
};
</script>
<style scoped lang="scss">
.border-rounded {
    border-radius: 50%;
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}

.h-100 {
    height: 100%;
}

.w-25p {
    width: 25%;
}
.w-33p {
    width: 33%;
}
.bottom-tag {
    &::after {
        content: '';
        position: absolute;
        width: 10%;
        transform: translateX(-50%);
        height: 2px;
        //background-color: black;
        //background-color: var(--bottom-tag-color);
        //background-color: var(--color-primary);
        margin-bottom: 1rem;
    }
}
.c-bs-1{
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.25);
}
</style>
