<template>
    <div>
        <div class="container">
            <div class="row pt-2 mb-8 mb-lg-2 pt-lg-4">
                <div class="col-lg-10 col-12  order-2 order-lg-1  ">
                    <div class="p-rr section-1">
                        <h2 class="mb-3 fs-lg-4 text-primary font-poppins-semibold text-uppercase">Our Mission and
                            Vision</h2>
                        <img src="../../assets/web/home/icons/quote.png" alt="">
                        <p class="mb-2 fs-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid
                            aspernatur
                            assumenda aut
                            atque
                            commodi, earum est laborum nemo nesciunt odit, quam qui rem ut
                            Lorem ipsum dolor
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum
                            dolor sit amet, consectetur Amet, cumque deserunt ex possimus quia quidem.
                        </p>
                        <!--                        <div class="row">-->
                        <!--                            <div class="col-12">-->
                        <!--                                <div class="social-i pl-5 pl-lg-0 mt-4">-->
                        <!--                                    <img src="../../assets/web/icons/fb.png">-->
                        <!--                                    <img class="right" src="../../assets/web/icons/twitter.png">-->
                        <!--                                    <img class="right" src="../../assets/web/icons/linkedin.png">-->
                        <!--                                    <img class="right" src="../../assets/web/icons/mail.png">-->

                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <div class="col-lg-2 col-12 order-1 order-lg-2">
                    <div class="pic ">
                        <img class="blue" src="../../assets/web/about/Rectangle-primary.png">
                        <img class="grey" src="../../assets/web/about/Rectangle-gray-dummy.png">
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Team-b'

};
</script>

<style scoped lang="scss">
.blue {

    position: absolute;
}

.grey {
    position: relative;
    /*z-index: 1px;*/
}

.right {
    margin-left: 28px;
}

.section-1 {
    background-color: #f4f4f4;
    border-radius: 400px 400px 0px 1px / 300px 296px 199px 182px;
    box-shadow: -2px 2px 5px 3px rgba(0, 0, 0, 0.25);

    @media (min-width: 992px) {
        max-height: 370px;
        border-radius: 186px 0px 0px 182px / 182px 23px 26px 176px;
        box-shadow: 0px 9px 7px 0px rgba(0, 0, 0, 0.25%);
    }
}

.pic img {

    position: absolute;
    left: 0px;
    top: 37.1875rem;
    width: 100%;
    height: auto;

    @media(min-width: 992px) {
        top: -27px;
        height: 421px;
        width: 363px;
        left: -177px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        position: absolute;
        left: 0px;
        top: 28.1875rem;
        width: 100%;
        height: auto;

    }
}

.p-rr {
    padding: 8rem 2rem 15rem 4rem;
    @media (min-width: 992px) {
        padding-right: 13rem;
        padding-left: 11rem;
        padding-bottom: 6rem;
        padding-top: 6rem;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 11rem 2rem 26rem 4rem;
    }

}

.social-i img {
    height: 14px;
    width: 14px;
    border-radius: 0px;

}

.social-i {
    position: absolute;
    /*left: 8.33%;*/
    /*right: 64px;*/
    /*top: 16.67%;*/
    /*bottom: 16.67%;*/

}
</style>
