<template>
    <div class="py-5 bg-gradient-2">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <base-heading-with-info class="mb-4" title="Experienced  & Professional Team"
                                            info="There are many variations of passages of Lorem Ipsum available alteration in some form, by injected"
                    >
                    </base-heading-with-info>
                </div>
                <div class="col-12 d-sm-none">
                    <div class="row">
                        <div class="col-12 col-lg-4 col-md-6" :class="{'mb-4 mb-lg-0':i < items.length-1}"
                             v-for="(item,i) in items" :key="i">
                            <member-card :img-url="item.img" :heading="item.heading"
                                         :role="item.role" :content="item.content"></member-card>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-lg-none">
                    <experienced-team-swiper></experienced-team-swiper>
                </div>
                <div class="col-12 mt-5 text-center">
                    <btn design="basic-b" text="View All" class="px-41 py-3 font-weight-500 fs-lg-1 b-2-primary font-poppins-medium" border-radius="4"></btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import memberImg1 from '../../../assets/web/home/teams/member-1.jpg';
import memberImg2 from '../../../assets/web/home/teams/member-2.jpg';
import memberImg3 from '../../../assets/web/home/teams/member-3.jpg';
import MemberCard from '@components/home/MemberCard';
import ExperiencedTeamSwiper from '@components/home/sections/ExperiencedTeamCarousel';

export default {
    name: 'ExperiencedProfessionalTeam',
    components: { ExperiencedTeamSwiper, MemberCard },
    data () {
        return {
            memberImg1: memberImg1,
            memberImg2: memberImg2,
            memberImg3: memberImg3,
            items: [
                {
                    img: memberImg1,
                    heading: 'Your Name Here',
                    role: 'Job Description',
                    content: '                    Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor mgnhe\n' +
                        '                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim'
                },
                {
                    img: memberImg2,
                    heading: 'Your Name Here',
                    role: 'Job Description',
                    content: '                    Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor mgnhe\n' +
                        '                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim'
                },
                {
                    img: memberImg3,
                    heading: 'Your Name Here',
                    role: 'Job Description',
                    content: '                    Lorem ipsum dolor sit amet, consectetur dft gg dipiscing elit, sed do eiusmod tempor mgnhe\n' +
                        '                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
