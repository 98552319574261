<template>
    <div class="pt-8">
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <base-breadcrumb :path="['News', 'News-1']"
                                         class="    "></base-breadcrumb>
                        <router-link to="/news/">
                            <btn design="basic-a" text="Back to all resources" size="md"
                                 class="pl-5 pr-3 lego-btn-arrow-left"
                                 border-radius="4"></btn>
                        </router-link>
                    </div>
                    <div class="col-12">
                        <div class="fl-y fl-a-c mt-4">
                            <h2 class="mb-4 text-primary fs-lg-4 font-poppins-bold">Buy Now, Pay Later: Is It Another
                                Debt
                                Trap?</h2>
                            <img src="../../assets/web/news/news-details.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="py-5">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Quis auctor elit sed vulputate mi sit amet. Et sollicitudin
                            ac
                            orci phasellus egestas tellus rutrum tellus. Ultrices eros in cursus turpis. Tortor
                            dignissim
                            convallis aenean et. Malesuada fames ac turpis egestas sed. Eget magna fermentum iaculis eu
                            non
                            diam phasellus vestibulum lorem. A lacus vestibulum sed arcu non odio euismod lacinia.
                            <br><br>
                            Consectetur lorem donec massa sapien faucibus et. Nulla pharetra diam sit amet nisl suscipit
                            adipiscing bibendum est. Ac tortor dignissim convallis aenean et tortor at. Adipiscing diam
                            donec adipiscing tristique risus. Ipsum dolor sit amet consectetur adipiscing elit
                            pellentesque
                            habitant. Consequat semper viverra nam libero justo.
                            <br><br>
                            Ac turpis egestas sed tempus urna et. Viverra accumsan in nisl nisi scelerisque.
                            Pellentesque
                            habitant morbi tristique senectus et. Eget sit amet tellus cras adipiscing enim eu turpis
                            egestas. Condimentum mattis pellentesque id nibh tortor id aliquet lectus proin. Sit amet
                            consectetur adipiscing elit.
                            <br><br>
                            Elementum pulvinar etiam non quam lacus suspendisse faucibus interdum. Urna id volutpat
                            lacus
                            laoreet non curabitur gravida. Diam vulputate ut pharetra sit amet aliquam id diam. Cursus
                            in
                            hac habitasse platea dictumst. Enim ut tellus elementum sagittis vitae et. Amet dictum sit
                            amet
                            justo donec enim diam. Sit amet commodo nulla facilisi nullam vehicula ipsum.
                            <br><br>
                            Elit ut aliquam purus sit amet luctus venenatis lectus. Adipiscing enim eu turpis egestas
                            pretium. Quis enim lobortis scelerisque fermentum. Enim eu turpis egestas pretium aenean
                            pharetra magna. Massa tincidunt dui ut ornare. Sapien et ligula ullamcorper malesuada proin.
                            Sed
                            euismod nisi porta lorem mollis.
                            <br><br>
                            Nisl tincidunt eget nullam non nisi est. Urna condimentum mattis pellentesque id nibh
                            tortor. In
                            cursus turpis massa tincidunt dui ut ornare. Elementum integer enim neque volutpat ac
                            tincidunt.
                            Scelerisque felis imperdiet proin fermentum. Ullamcorper eget nulla facilisi etiam dignissim
                            diam. Facilisis magna etiam tempor orci eu lobortis elementum nibh.

                        </div>
                    </div>
                    <div class="col-12">
                        <div class="fl-x py-2 b-1-top b-1-bottom">
                            <p class="mr-3 mb-0 text-2">Share</p>
                            <router-link to="/" class="mr-3 text-primary">
                                <i class="fa fa-facebook"></i>
                            </router-link>
                            <router-link to="/" class="mr-3 text-primary">
                                <i class="fa fa-twitter"></i>
                            </router-link>
                            <router-link to="/" class="text-primary">
                                <i class="fa fa-linkedin"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="relatedNews">
            <related-news></related-news>
        </div>
    </div>
</template>

<script>
import BaseBreadcrumb from '../../components/ui/BaseBreadcrumb';
import RelatedNews from '../../components/news/RelatedNews';

export default {
    name: 'NewsDetailsPage',
    components: { RelatedNews, BaseBreadcrumb }
};

</script>

<style scoped>

</style>
