<template>
    <div class="fl-y fl-a-c text-white mb-3 mb-lg-0">
        <i :class="icon" class="mb-3"></i>
        <div ref="p" class="text-lg line-right mb-4 mb-lg-5 oswald-600 "><span v-if="counter<10">0</span>{{ counter }}</div>
        <h4 class="poppins-400 fs-3">{{ heading }}</h4>
    </div>
</template>
<script>
export default {
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        count: {
            type: [Number, String],
            required: false,
            default: 0
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        from: {
            type: [Number, String],
            default: 0
        },
        to: {
            type: [Number, String],
            required: true,
            default: 0
        },
        format: {
            type: Function,
            default: (num) => parseInt(num)
        },
        duration: {
            type: Number,
            default: 1 // Duration of animation in seconds
        },
        easing: {
            type: String,
            default: 'Power1.easeOut'
        },
        delay: {
            type: Number,
            default: 0 // Delay the animation in seconds
        }
    },
    data () {
        return {
            observer: null,
            counter: 0
        };
    },

    mounted () {
        console.log('mounted');
        const observer = new IntersectionObserver(this.handleIntersection);
        observer.observe(this.$refs.p);
        this.observer = observer;
    },
    methods: {
        handleIntersection (entries) {
            console.log('handleIntersection');
            if (this.counter > 0) {
                return;
            }
            const duration = 500;
            if (entries[0].isIntersecting) {
                const that = this;
                const count = parseInt(that.count, 10);
                const i = setInterval(function () {
                    that.counter += 1;
                    if (that.counter === count) {
                        clearInterval(i);
                    }
                }, duration / count);
            }
        }
    }
};
</script>
<style scoped lang="scss">
.text-lg {
    font-size: 4rem;
    font-weight: bold;
    color: var(--color-white);
}

</style>
