<template>
    <div class="pt-8 ">
        <div id="newsListTabs">
            <news-list-tabs></news-list-tabs>
        </div>
        <div id="premium-membership">
            <premium-membership></premium-membership>
        </div>
        <div id="top-foote">
            <top-footer-image></top-footer-image>
        </div>
    </div>
</template>

<script>

import NewsListTabs from '@components/news/sections/NewsListTabs';
import PremiumMembership from '@components/home/sections/PremiumMembership';
import TopFooterImage from '@components/home/sections/TopFooterImage';
export default {
    name: 'NewsPage',
    components: { TopFooterImage, PremiumMembership, NewsListTabs }
};
</script>

<style scoped>

</style>
