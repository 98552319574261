<template>
    <div>
        <div class="">
            <meet-our-team></meet-our-team>
        </div>

        <div class="fl-x-cc">
           <team-a></team-a>
        </div>
<!--        <div>-->
<!--            <team-b></team-b>-->
<!--        </div>-->

        <div class="py-6 py-lg-3">
            <team-2></team-2>
        </div>
        <div>
            <team-d></team-d>
        </div>
        <div class="py-6 py-lg-3 pb-lg-7 pb-6">
            <team-e></team-e>
        </div>
        <div class="py-5">
            <custom-pagination :current-page="1" :last-page="8"></custom-pagination>
        </div>

        <div>
            <premium-membership></premium-membership>
            <top-footer-image></top-footer-image>

        </div>
    </div>
</template>

<script>
import MeetOurTeam from '@components/Our-Team/MeetOur-Team';

// import TeamB from '@components/Our-Team/Team-b';

import PremiumMembership from '@components/home/sections/PremiumMembership';
import TopFooterImage from '@components/home/sections/TopFooterImage';
import TeamA from '@components/Our-Team/Team-A';
import TeamD from '@components/Our-Team/Team-d';
// import TeamB from '@components/Our-Team/Team-b';
import TeamE from '@components/Our-Team/Team-e';
// import Try from '@components/Our-Team/Try';
import Team2 from '@components/Our-Team/Team-2';
import CustomPagination from '@components/CustomPagination';

export default {
    name: 'Our-Teams',
    components: { CustomPagination, Team2, TeamE, TeamD, TeamA, TopFooterImage, PremiumMembership, MeetOurTeam }
};

</script>

<style scoped>

</style>
